.menus {
  h2 {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 1rem;

    a:hover {
      text-decoration: none;
    }
  }

  .img {
    position: relative;
    z-index: 1;
    overflow: hidden;

    img {
      display: block;
      width: calc(100% + 40px) !important;
      transition: opacity 0.35s, transform 0.35s;
      transform: translate3d(-40px, 0, 0);
      max-width: none;
    }

    &:hover {
      img {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  .overlay-effect {
    .overlay {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 20;
      background: rgba(100, 194, 158, 0.8); 
      overflow: hidden;
      transition: all 0.5s;
      visibility: visible;

      .expand {
        display: block;
        position: absolute;
        z-index: 100;
        text-align: center;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 30px;
        left: 0; 
        right: 0; 
        bottom: 47%; 
        margin: 0 auto -20px auto;
        text-decoration: none;
      }
    }

    .img.hover .overlay {
      height: 100%;
      opacity: 1;
      visibility: visible;
    }
  }
}