.contacts {
  h1 {
    margin-top: 0;
    margin-bottom: 2rem;
  }

  h5 {
    margin-top: 0;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }

  ul li {
    font-size: 13px;
    list-style: none;
  }

  #map {
    img {
      border: 1px solid grey;
    }
  }
}