h1.arrow {
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1px; 
  background: url('../images/h1-bg.png') no-repeat center bottom;
  padding-bottom: 30px;
  font-weight: bold;
}

#header .logo svg path {
  fill: rgb(17, 58, 135);
}

.navigation {
  li {
    a {
      text-transform: uppercase;
      font-size: 13px;
    }
  }
}

footer { 
  background-color: #242830; 
  padding: 20px 0 20px;
  font-size: 12px;

  a {
    color: #a1a9b0;
  }

  p {
    color: #fff;
  }

  ul.legals {
    list-style: none;
    color: #fff;

    li {
      display: inline-block;
      margin-top: 0;

      &::after {
        content: ' |';
      }

      &:last-child::after {
        content: '';
      }
    }
  }

  .credit {
    text-align: right;
    p {
      margin: 0.8rem;
    }
  }
}

.section-padding {
  padding-top: 90px;
  padding-bottom: 100px;
}