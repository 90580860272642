.delivery {
  padding: 70px 0;
  background-color: #242830;

  p {
    margin: 0;
  }

  &-button {
    display: inline-block;
    padding: 25px 40px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    border: 1px solid #fff;
    text-decoration: none;

    &:hover, &:focus {
      text-decoration: none;
      color: #3dc9b3;
      border: solid 1px #3dc9b3; 
    }

    &:visited {
      color: #fff;
    }
  }
}